<template>
  <div
    class="components_task_sidebar_widget_container_page bbox"
  >
    <div class="wrapper d-flex flex-column">
      <draggable
        class="control_draggable d-flex flex-wrap align-center flex-shrink-0"
        :list="list"
        :group="{name: 'widget', pull: editable ? 'clone' : false, put: false }"
        :clone="cloneWidget"
        :sort="false"
      >
        <template
          v-for="(item, index) in list"
        >
          <widget-button
            v-if="!(public_notice && item.type === 11)"
            class="cp"
            :key="index"
            :name="item.name"
            :title="item.title"
            :type="item.type"
            :editable="editable"
            @click.native.stop="clickNewWidget(item)"
          ></widget-button>
        </template>
      </draggable>
      <div
        v-if="copyWidgetList && copyWidgetList.length"
        class="reference_gruop"
      >
        <div
          class="title_group d-flex align-center justify-space-between"
        >
          <!--引用的控件-->
          <div
            class="title"
          >
            复制的控件 [{{ copyWidgetList.length }}]
          </div>
          <div
            class="clear cp"
            @click.stop="clearCopyList"
          >
            清空
          </div>
        </div>
        <div
          class="copy_list_group"
        >
          <draggable
            class="control_draggable flex-shrink-0"
            :list="copyWidgetList"
            :group="{
              name: 'widget',
              pull: editable ? 'clone' : false,
              put: false
            }"
            :clone="pasteWidget"
            :sort="false"
          >
            <template
              v-for="(item, index) in copyWidgetList"
            >
              <CopyButton
                :key="index"
                :p-task-detail="item"
                @click.stop.native="clickCopyWidget(item)"
              ></CopyButton>
            </template>
          </draggable>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
import WidgetButton from './Button.vue'
import { PTaskDetail } from '@/types'
import store from '@/store'
import CopyButton from './CopyButton.vue'
import tools from '@/api/tools'
export default {
  components: {
    draggable,
    WidgetButton,
    CopyButton
  },
  props:{
    editable: {
      type: Boolean,
      default: true,
    },
    public_notice: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return {
      list:  [
        {name:'text', type: 1, title: '文本'},
        {name: 'number', type: 2, title: '数字'},
        {name: 'money', type: 3, title: '金额'},
        {name: 'time', type: 4, title: '时间'},
        // {name: 'formula', type: 5, title: ''},
        {name: 'file', type: 6, title: '附件'},
        {name: 'hyperlink', type: 7, title: '链接'},
        // {name: 'location', type: 8, title: ''},
        {name: 'picture', type: 9, title: '图片'},
        {name: 'contact', type: 10, title: '名片'},
        {name: 'wisdom', type: 11, title: '智慧桌'},
      ],
    }
  },
  computed: {
    task(){
      return this.$store.state.task;
    },
    taskDeatails(){
      const {task} = this;
      const {taskDeatails} = task;
      return taskDeatails;
    },
    task_config(){
      return this.$store.state.task_config
    },
    // editable(){
    //   const {task_config} = this;
    //   const {editable} = task_config;
    //   return editable;
    // }
    copyWidgetList () {
      return store.state.copyWidgetList
    }
  },
  methods:{
    clearCopyList () {
      store.state.copyWidgetList = []
      tools.removeCookie('copyWidgetData')
    },
    pasteWidget (params) {
      const newUnit =  new PTaskDetail(Object.assign(
        {},
        params,
        {
          id: undefined,
          modified: undefined,
          remindList: [],
          sort: undefined,
          sortJson: undefined,
          taskId: undefined,
          updatenum: undefined,
          islock: undefined,
          widget_sort: this.getWidgetSort(),
          userId: undefined,
          userlock: undefined
        }
      ))

      if (newUnit && newUnit.controlLinkList && newUnit.controlLinkList.length) {
        for (let i in newUnit.controlLinkList) {
          delete newUnit.controlLinkList[i].id
          // 遍历fileList 并将其id删除
          for (let j in newUnit.controlLinkList[i].fileList) {
            delete newUnit.controlLinkList[i].fileList[j].id
          }
        }
      }
      // console.log({ newUnit })
      return newUnit
    },
    cloneWidget ({ type }) {
      // // console.log(this.$store.state.task);
      // console.log('clone')
      let res = this.newWidget({ type })
      res = new PTaskDetail(res)
      return res
    },
    /**
     * 点击克隆
     */
    clickCopyWidget (params) {
      console.log('click clone', params)
      const { editable } = this
      if (!editable) {
        return
      }
      if (params.type === 11){
        const membership_privileges = this.$api.user.membershipPrivileges({
          hint: '“智慧桌”控件是“智慧项目管理实训桌”硬件设备的配套控件，使老师能够在课堂上带领学生进行实践任务的布置、演练与计时，此控件仅限VIP会员使用。'
        });
        if (!membership_privileges) {
          return false
        }
      }
      const { taskDeatails } = store.state.task
      const newOne = this.pasteWidget(params)
      taskDeatails.push(newOne)
      this.$nextTick(function () {
        this.$emit('handleAddNewWidget')
      })

    },
    /**
     * 点击 新建控件
     */
    clickNewWidget({type}){
      const { editable } = this
      if (!editable) {
        return
      }
      if(type == 11){
        const membership_privileges = this.$api.user.membershipPrivileges({
          hint: '“智慧桌”控件是“智慧项目管理实训桌”硬件设备的配套控件，使老师能够在课堂上带领学生进行实践任务的布置、演练与计时，此控件仅限VIP会员使用。'
        });
        if(!membership_privileges){
          return false
        }
      }
      const {taskDeatails} = this.$store.state.task
      const data = this.newWidget({ type })
      taskDeatails.push(new PTaskDetail(data))
      this.$nextTick(function () {
        this.$emit('handleAddNewWidget')
      })
      // let {task_details} = this.$store.state;
      // let data = this.newWidget({type});
      // task_details = this.$tools.deepClone(task_details);
      // if(task_details == null){
      //   task_details = [];
      // }
      // task_details.push(data);
      // this.$store.commit('set_task_details', task_details);
      // this.$nextTick(function(){
      //   this.$parent.controlPartScrollToBottom();
      // })
    },
    getWidgetSort () {
      const { state, commit } = store
      const { task_config } = state
      let widget_sort = 0
      widget_sort = task_config.widget_sort++
      return widget_sort
    },
    /**
     * 新建控件
     */
    newWidget({type}){
      const {taskDeatails, task, $store} = this;
      const {state, commit} = $store;
      const {task_config} = state;
      let widget_sort = 0;
      widget_sort = task_config.widget_sort;
      widget_sort += 1;
      commit('set_task_config', Object.assign({}, task_config, {widget_sort}));
      let data = {
        type: type,
        remark : '', // 描述/标题
        content : '', // 内容/值
        controlLinkList: [],
        widget_sort,
        remindList: [],
      }
      if(type == 2){
        // 数值
        data.unit = ''; // 单位
      }else if(type == 3){
        //金额
        data.unit = '元';
      }else if(type == 4){
        data.timetype = 1;
      }else if(type == 7){
        data.hyperlink_list = [];
      }else if(type == 9){
        data.fileList = [];
      }else if(type == 6){
        data.fileList = [];
      }else if(type == 10){
        data.userList = [];
      }

      // // console.log(data);
      return data;
    },
  }
}
</script>
<style lang="scss" scoped>
.components_task_sidebar_widget_container_page{
  @include scrollbar;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  padding: 10px;
  position: relative;
  .wrapper {
    @include scrollbar;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    position: relative;
  }
  .control_draggable {
    position: sticky;
    z-index: 3;
    top: 0;
    left: 0;
  }
  .reference_gruop {
    width: 100%;
    .title_group {
      font-size: 13px;
      color: #999;
      padding: 5px 10px;
      width: 310px;
      .clear {
        color: $main_blue;
      }
    }
    .copy_list_group {
      padding: 10px;
    }
  }

}
</style>
