<template>
  <div
    class="components_task_side_bar_teach_pages_price_page bbox d-flex align-start bbox_wrapper"
  >
    <div
      class="d-flex flex-column align-center wrapper justify-space-between"
    >
      <div>
        <div
          class="title align-self-start"
        >
          教学类型设置
        </div>
        <div
          class="task_type_wrapper un_sel"
        >
          <div
            class="key"
          >
            教学类型:
          </div>
          <div
            class="custom-select"
            ref="taskSelectMenu"
          >
            <!-- 显示当前选择的值 -->
            <div
              class="select-display"
              :class="{
                on: selectedLabel
              }"
              @click="toggleMenu"
            >
              {{ selectedLabel || '请选择教学类型' }}
              <span
                v-show="canEditTeachingType"
                class="arrow"
                :class="{ 'arrow-up': isMenuOpen }"
              ></span>
            </div>
            <!-- 下拉菜单 -->
            <div
              v-if="isMenuOpen"
              class="select-options"

            >
              <div
                v-for="item in teachingTypeList"
                :key="item.value"
                class="option"
                @click="selectOption(item)"
                :class="{ 'selected': item.value === teachingType }"
              >
                {{ item.label }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="teachingType === 3"
          class="price_group d-flex align-start flex-column"
        >
          <div
            class="key flex-shrink-0 mb-10"
          >
            设置价格：
          </div>
          <div
            class="price_box"
          >
            <input
              v-model="price"
              ref="input"
              type="text"
              class="price bbox"
            >
            <div
              class="symbol"
            >
              ¥
            </div>
            <div
              class="hint"
              :class="{error: error}"
            >
              {{ hint }}
            </div>
          </div>
        </div>
        <div
          class="intro_group d-flex align-start flex-column mb-20"
        >
          <div
            class="name flex-shrink-0 mb-10"
          >
            设置简介:
          </div>
          <el-input
            class="intro"
            type="textarea"
            :autosize="{ minRows: 5, maxRows: 5}"
            placeholder="输入简介内容"
            v-model="intro"
            resize="none"
          >
          </el-input>
        </div>
        <div
          class="notice"
        >
          *上传教学文件后，系统将进行内容审核，审核通过后将自动发布，请关注并耐心等待。
        </div>
      </div>
      <div class="fun_group d-flex align-center justify-space-between">
        <div class="button btn_dark"
          :class="{disabled: !can_save}"
          @click="doSave"
        >保存</div>
        <div class="button btn_light"
          @click="doCancel"
        >取消</div>
      </div>
    </div>
  </div>
</template>
<script>
import store from '@/store'
import { mapState } from 'vuex'
export default {
  data(){
    return {
      price: '',
      intro: '',
      // 选择教学类型
      selectedLabel: null,
      isMenuOpen: false,
      teachingType: '',
      teachingTypeList: [
        {
          label: '学校教学实践',
          value: 1
        },
        {
          label: '企业岗位应用',
          value: 2
        },
        {
          label: '付费培训',
          value: 3
        }
      ],
    }
  },
  watch:{
    //只能输入数字
    price: function(val){
      if(val){
        val = String(val);
        this.price = val.replace(/[^\d^]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.');
      }
    },
  },
  computed: {
    ...mapState({
      task: state => state.task,
      coursePackage: state => state.course_package.operationData,
      taskConfig: state => state.tasks.taskConfig,
    }),
    // 是否可以编辑教学类型
    canEditTeachingType () {
      const { task, coursePackage } = this;
      if (coursePackage) {
        return false
      }
      const { teachingType, id } = task.task;
      return !(id && teachingType)
    },
    // 任务类型说明
    teachTypeHint (){
      // 教学
      return '*上传教学文件后，系统将进行内容审核，审核通过后将自动发布，请关注并耐心等待'
      // 企业类型
      // *上传教学文件后，系统将进行内容审核，审核通过后将自动发布，请关注并耐心等待。
      // 付费类型
      // *上传教学文件后，系统将进行内容审核，审核通过后将自动发布，请关注并耐心等待。
    },
    hint(){
      const {price} = this;
      let hint = '';
      // if(price > 50){
      //   hint = '售价不得高于¥50';
      // }else{
        if(price !== ''){
          hint = `个人实际收益：¥${Math.round(price * 0.7)}`
        }
      // }
      return hint;
    },
    error(){
      const {price} = this;
      // if(price > 50){
      //   return true;
      // }
      return false;
    },
    can_save(){
      const { price, error, teachingType } = this;
      console.log(price, teachingType)
      if (teachingType === ''){
        return false;
      }
      if( teachingType === 3 && price === ''){
        return false;
      }
      return true;
    }
  },
  methods: {
    inputFocus(){
      if (this.$refs.input) {
        this.$refs.input.focus();
      }
    },
    doCancel(){
      const {task_type} = this.$store.state.task_config;
      if(task_type == 2){
        this.$emit('handleChangePage', 'make');
        return;
      }
      this.$emit('handleChangePage', 'start')
    },
    doSave () {
      const { can_save, price, intro, teachingType } = this;
      const userid = this.$tools.getUserId()
      if(!can_save) {
        return;
      }
      if(this.teachingType === 3 && this.price === ''){
        this.$notice({
          desc: '请输入价格',
          type: 'error'
        })
        return;
      }
      if (this.teachingType === ''){
        this.$notice({
          desc: '请选择教学类型',
          type: 'error'
        })
        return;
      }
      this.task.task.teachingType = teachingType;
      this.$store.commit('set_task_config', Object.assign(
        {},
        this.$store.state.task_config,
        {
          price: price*100,
          introduction: intro,
          task_type: 2,
        }

      ))
      store.state.task.task.signUserId = userid
      this.$emit('handleChangePage', 'make');
    },
    init(){
      const {
        coursePackage
      } = this
      if (coursePackage) {
        const {
          teachingType
        } = coursePackage
        if (teachingType) {
          const aim = this.teachingTypeList.find((item) => {
            return item.value === teachingType
          })
          if (aim) {
            this.teachingType = teachingType
            this.selectedLabel = aim.label
          }
        }
      }
      const { task_config } = this.$store.state
      const { price, introduction } = task_config
      if(price!== null && price !== ''){
        this.price = price/100;
      }
      this.intro = introduction ?? '';
      const {
        teachingType,
      } = this.task.task
      if (teachingType !== null && teachingType !== '' && teachingType!== undefined) {
        this.teachingType = teachingType;
        this.selectedLabel = this.teachingTypeList.find(item => item.value === teachingType).label;
      }

    },
    // 切换菜单显示状态
    toggleMenu() {
      const {
        canEditTeachingType
      } = this
      if (!canEditTeachingType) {
        return
      }
      this.isMenuOpen = !this.isMenuOpen;
    },
    // 选择选项
    selectOption(item) {
      this.teachingType = item.value;
      this.selectedLabel = item.label;
      this.isMenuOpen = false;
    },
    // 处理全局点击事件
    handleGlobalClick(event) {
      const selectElement = this.$refs.taskSelectMenu;
      if (!selectElement.contains(event.target)) {
        this.isMenuOpen = false;
      }
    }
  },
  mounted(){
    console.log('初始化')
    this.init();
    // 在组件挂载后添加全局点击事件监听器
    window.addEventListener('click', this.handleGlobalClick);
  },
  beforeDestroy() {
    // 在组件销毁前移除全局点击事件监听器
    window.removeEventListener('click', this.handleGlobalClick);
  }

}
</script>
<style lang="scss" scoped>
.components_task_side_bar_teach_pages_price_page{
  width: 100%;
  height: 100%;
  padding: 0 20px;
  .wrapper{
    width: 100%;
    height: 100%;
  }
  .title{
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    line-height: 24px;
    padding-top: 40px;
    padding-bottom: 20px;
  }
  .task_type_wrapper {
    width: 100%;
    padding-bottom: 20px;
    .key{
      font-size: 16px;
      color: #333333;
      line-height: 21px;
      padding-right: 10px;
      padding-bottom: 10px;
    }
    .task_type_list {
      /* 可以在这里添加样式，例如设置宽度、高度、边框等 */
      width: 200px;
      height: 30px;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 4px;
    }
    .custom-select {
      position: relative;
      width: 210px;
      background-color: #FBFBFB;
    }

    .select-display {
      padding: 8px 12px;
      border: 1px solid #ccc;
      border-radius: 4px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #ccc;
      font-size: 14px;
      &.on {
        color: #606266;
      }
    }

    .arrow {
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid #999;
      transition: transform 0.2s ease;
    }

    .arrow-up {
      transform: rotate(180deg);
    }

    .select-options {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      border: 1px solid #ccc;
      border-top: none;
      border-radius: 0 0 4px 4px;
      background-color: #fff;
      z-index: 9;
      font-size: 14px;
    }

    .option {
      padding: 8px 12px;
      cursor: pointer;
    }

    .option:hover {
      background-color: #f0f0f0;
    }

    .option.selected {
      background-color: #e0e0e0;
    }
  }
  .price_group{
    width: 100%;
    padding: {
      //top: 20px;
      bottom: 35px;
    }
    .key{
      font-size: 16px;
      color: #333333;
      line-height: 21px;
      padding-right: 10px;
    }
    .price_box{
      flex:1;
      position: relative;
      .price{
        width: 100%;
        outline: none;
        background: #FBFBFB;
        border-radius: 4px;
        border: 1px solid #E0E0E0;
        position: relative;
        z-index: 2;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: #333333;
        line-height: 38px;
        padding: {
          left: 36px;
        }
      }
      .symbol{
        position: absolute;
        z-index: 3;
        top: 0;
        left: 16px;
        font-size: 16px;
        color: #666666;
        line-height: 40px;
      }
      .hint{
        position: absolute;
        z-index: 3;
        left: 0;
        bottom: -30px;
        font-size: 14px;
        color: $main_blue;
        line-height: 20px;
        &.error{
          color: #D82E0D;
        }
      }
    }
  }
  .intro_group{
    width: 100%;
  }
  .notice{
    font-size: 14px;
    color: #666666;
    line-height: 19px;
    text-align: justify;
    text-align-last: left;
    text-justify: inter-ideograph;
  }
  .fun_group{
    width: 100%;
    padding-top: 80px;
    .button{
      font-size: 14px;
      line-height: 44px;
      border-radius: 4px;
      padding: 0 60px;
      &.disabled{
        background-color: #CCCCCC;
      }
    }
  }
}
</style>


<style lang="scss">
.components_task_side_bar_teach_pages_price_page{
  .intro{
    .el-textarea__inner{
      font-family     : "MicroSoft YaHei", "PingFang SC";
      background-color: #fbfbfb;
      border          : 1px solid #e0e0e0;
      font-size       : 14px;
      color           : #333;
      &:focus{
        border-color: #e0e0e0;
      }
    }
  }
}
</style>
