<template>
  <div
    class="components_popup_call_task_page bbox"
  >
    <div class="mask d-flex align-center justify-center"
      :style="{zIndex: index}"
    >
      <div class="container bbox d-flex flex-column">
        <div class="title_group d-flex align-center un_sel justify-center flex-shrink-0">
          <div
            class="title"
          >
            调用新建任务
          </div>
          <div
            class="close cp"
            @click="doClose();"
          ></div>
        </div>
        <div class="main d-flex flex-column">
          <div class="appoint_group flex-shrink-0 d-flex align-center bbox"
            v-if="appoint !== null"
          >
            <div class="group d-flex align-center bbox">
              <div class="clear cp"
                @click="changeAppoint(null);"
              ></div>
              <img :src="`${$avatar_url}${appoint.thumbnail}@!small200`" alt="" class="avatar">
              <div class="name">{{appoint.name}}:</div>
            </div>
          </div>
          <div class="search_group flex-shrink-0 d-flex align-start bbox" >
            <search-type
              ref="search_type"
              :task_call_config="task_call_config"
              :appoint="appoint"
              :props_keyword="search_keyword"
              @handleChange="changeSearch"
            ></search-type>
            <tag
              v-if="tag_list.length && tag_status"
              :list="tag_list"
              @handleChange="changeTag"
            ></tag>
          </div>
          <div class="search_content bbox">
            <div class="default_wrapper">
              <default-list
                :type="search_type"
                :appoint="appoint"
                :keyword="search_keyword"
                :tags="search_tags"
                v-if="is_list"
                ref="default_list"
                @handleCallTask="callTask"
              ></default-list>
              <search-result
                v-if="result_status"
                :keyword="search_keyword"
                ref="search_result"
                @handleCallTask="callTask"
                @handleAppoint="changeAppoint"
              ></search-result>
              <custom
                v-if="custom_status"
                @handleCallTask="callTask"
              ></custom>
              <purchase-and-sell
                v-if="sell_status"
                ref="purchaseAndSell"
                :task_call_config="task_call_config"
              ></purchase-and-sell>
            </div>
          </div>
        </div>

      </div>
    </div>
    <custom-classify
      v-if="switch_custom"
      @handleClose="closeCustom"
      @handleCallTask="callTask"
    ></custom-classify>
  </div>
</template>

<script>
import SearchType from './CallTask/SearchType.vue';
import Tag from './CallTask/Tag.vue';
import DefaultList from './CallTask/DefaultList.vue';
import SearchResult from './CallTask/SearchResult.vue';
import CustomClassify from '@/components/PopUp/CustomClassify.vue';
import Custom from './CallTask/Custom.vue';
import PurchaseAndSell from './CallTask/PurchaseAndSell.vue'
export default {
  components: {
    SearchType,
    Tag,
    DefaultList,
    SearchResult,
    CustomClassify,
    Custom,
    PurchaseAndSell,
  },
  data(){
    return {
      index: 1,
      task_call_config: {
        search_type: 'default',
        search_keyword: '',
        switch_custom: false,
        tag_list: [],
        appoint: null,
        search_tags: '',
        package: false, // 搜索任务/教学包
        package_id: undefined, // 教学包id
        p_package: undefined, // 教学包数据
      }
    }
  },
  watch: {
    'task_call_config.package': function(val) {
    }
  },
  computed:{
    search_type(){
      const {task_call_config} = this;
      const {search_type} = task_call_config;
      return search_type;
    },
    search_keyword(){
      const { task_call_config } = this
      const { search_keyword } = task_call_config
      return search_keyword
    },
    switch_custom(){
      const {task_call_config} = this;
      const {switch_custom} = task_call_config;
      return switch_custom;
    },
    tag_list(){
      const {task_call_config} = this;
      const {tag_list} = task_call_config;
      return tag_list;
    },
    appoint(){
      const {task_call_config} = this;
      const {appoint} = task_call_config;
      return appoint;
    },
    search_tags(){
      const {task_call_config} = this;
      const {search_tags} = task_call_config;
      return search_tags;
    },
    is_list(){
      const {search_type, search_keyword, appoint, search_tags, sell_status} = this;
      if((search_type === 'craft' || search_keyword === '' || appoint !== null || search_tags != '') && search_type !== 'custom' && !sell_status){
        return true;
      }

      return false;
    },
    result_status () {
      const { is_list, search_type, sell_status } = this
      if (!is_list && search_type !== 'custom' && !sell_status) {
        return true
      }
      return false
    },
    custom_status(){
      const {search_type} = this;
      if(search_type === 'custom'){
        return true;
      }
      return false;
    },
    tag_status () {
      const {search_type} = this
      if (search_type === 'custom') {
        return false
      }
      return true
    },
    sell_status () {
      const {search_type} = this
      if(search_type === 'sell'){
        return true
      }
      if(search_type === 'purchase'){
        return true
      }
      return false
    }
  },
  methods: {
    doClose () {
      this.$emit('handleClose');
    },
    changeSearch (params) {
      console.log(params, this)
      // return
      const { type, keyword } = params || {}
      console.log(keyword, type)
      if (type !== undefined) {
        this.changeConfig('search_type', type)
        this.changeConfig('search_keyword', '')
      }
      if (keyword !== undefined) {
        this.changeConfig('search_keyword', keyword)
      }
      console.log(this.task_call_config)
      const { is_list, sell_status } = this
      console.log({ is_list, sell_status })
      if (is_list) {
        if (this.$refs.default_list) {
          this.$nextTick(function() {
            this.$refs.default_list.handleChange()
          })
          return
        }
      } else {
        if (this.$refs.search_result) {
          this.$nextTick(function() {
            this.$refs.search_result.handleChange()
          })
          return
        }
      }
      if (sell_status) {
        console.log(this.$refs.purchaseAndSell)
      }
    },
    openCustom () {
      this.$store.commit('set_task_config', Object.assign(
        {},
        this.$store.state.task_config,
        {call_custom:true},
      ))
      this.switch_custom = true
    },
    closeCustom () {
      // console.log('closeCustom')
      this.$store.commit('set_task_config', Object.assign(
        {},
        this.$store.state.task_config,
        {call_custom:false}
      ))
      // this.switch_custom = false;
      this.changeConfig('switch_custrom', false)
    },
    callTask (data) {
      // // 关闭再打开
      // this.$api.moduleTask.closeTask();
      // // this.closeCustom();
      // this.$api.moduleTask.openTask({taskId: data.id, config: {call: true}});
      // // console.log(data);
      // return;
      console.log(this.$store.state.task_config)
      this.$emit('handleCall', {
        taskId: data.id,
        config: {
          // select_target: true,
          // 25/2/18 调用返回后无添加联系人按钮 提: 程
          select_target: this.$store.state.task_config.select_target,
          jump: true,
          use: true,
          open_by_call: true, // 通过调用打开, 如果关闭时modified为false 则返回新建页
          open_with_call_page: false
        }
      })
      this.doClose()
    },
    async getMyTag () {
      // const res = await this.$global.doPost({
      //   url: 'projectTask/myTags',
      //   data: {keyword: ''}
      // })
      const res = await this.$api.tag.getList()
      // this.$set(this, 'tag_list', res.contents.tagList);
      this.changeConfig('task_list', res.contents.tagList)
    },
    init () {
      this.getMyTag()
    },
    changeAppoint (item) {
      // this.$set(this, 'appoint', item);
      this.changeConfig('appoing', item)
      this.$refs.search_type.reset()
    },
    changeTag (tags) {
      this.changeConfig('search_tags', tags)
      const { is_list } = this
      if (is_list) {
        if (this.$refs.default_list) {
          this.$refs.default_list.handleChange()
        }
      } else {
        if (this.$refs.search_result) {
          this.$refs.search_result.handleChange()
        }
      }
    },
    changeConfig (key,val) {
      this.task_call_config[key] = val
    },

  },
  mounted(){
    const zindex = this.$utils.getZIndex();
    this.index = zindex;
    this.init();
  },
}
</script>
<style lang="scss" scoped>
.components_popup_call_task_page{
  .mask{
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    background-color: rgba(0,0,0,0.3);
  }
  .container{
    width: 100%;
    height: 100%;
    max-width: 80%;
    // min-width: 1000px;
    // max-height: 80%;
    max-height: 90%;
    // min-height: 800px;
    border-radius: 4px;
    box-shadow:0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    background-color:#fff;
  }
  .appoint_group{
    width: 100%;
    padding: 16px 20px 0;
    .clear{
      @include background;
      width: 16px;
      height: 16px;
      background-image: url(~@/assets/images/task/clear_appoint.png);
    }
    .avatar{
      width: 32px;
      height: 32px;
      border-radius: 4px;
      margin: 0 10px;
    }
    .name{
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      line-height: 24px;
    }
  }
  .title_group{
    height: 60px;
    position: relative;
    width: 100%;
    .title{
      font-size: 16px;
      color: #333333;
    }
    .close{
      @include background(12px, 12px);
      width: 20px;
      height: 20px;
      background-image: url(~@/assets/images/common/close.png);
      position: absolute;
      z-index: 3;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
    }
  }
  .main{
    background-color: #f8f8f8;
    width: 100%;
    height: 100%;
    overflow: auto;
    border-radius: 0 0 4px 4px;
  }
  .search_group{
    overflow: hidden;
    width: 100%;
    padding: 16px 20px;

  }
  .search_content{
    width: 100%;
    flex: 1;
    padding:10px 10px;
    overflow: hidden;
    .default_wrapper{
      width: 100%;
      height: 100%;
      overflow: auto;
    }
  }
}
</style>
