<template>
  <div
    class="components_task_sidebar_teach_container_page bbox"
  >
    <page-start
      v-show="page_status=='start'"
      @handleChangePage="changePage"
      :can_teach="can_teach"
    ></page-start>
    <page-price
      v-show="page_status == 'price'"
      @handleChangePage="changePage"
      ref="page_price"
    ></page-price>
    <page-make
      v-show="page_status == 'make'"
      @handleChangePage="changePage"
      @handleSubmit="doSumbit"
      @handleSaveDraft="saveDraft"
      @handleSavaTask="handleSavaTask"
      :edit="edit"
      :use="use"
      :preview="preview"
      :maintain="maintain"
      :buy="buy"
      :sell="sell"
      :modify_mark="modify_mark"
      :show_intro="show_intro"
      :shopping_mail="shopping_mail"
      :submit-method="doSumbit"
      :check-method="chenkAudit"
      @handleReEdit="handleReEdit"
    ></page-make>
  </div>
</template>
<script>
/**
 * 教学查看状态
 *
 * 编辑/预览/使用
 *
 */
import PageStart from './Pages/Start.vue';
import PagePrice from './Pages/Price.vue';
// import PageMake from './Pages/Make.vue'
import PageMake from '@/components/resource_library/teaching_task/teaching_area/make'
import store from '@/store'
import request from '@/api/request'
import { mapState } from 'vuex'
export default {
  components: {
    PageStart,
    PagePrice,
    PageMake,
  },
  props: {
    can_teach: {
      type: Boolean,
      default: false,
    },
    sell: {
      type: Boolean,
      default: false,
    },
    show_intro: {
      type: Boolean,
      default: true,
    },
    shopping_mail: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return {
      page_status: 'start',
      modify_mark: true, // 修改标记 // 当task_type == 2 时默认为false 其他为true
    }
  },
  computed: {
    ... mapState({
      task: state => state.task,
      template_my_list: state => state.template.template_my_list,
      taskConfig: state => state.tasks.taskConfig,
    }),
    auditMode () {
      return store.state.task_config.auditMode
    },
    edit () {
      const {
        modify_mark,
        auditMode
      } = this
      const { task_config,task } = this.$store.state
      const { name, params } = this.$route
      const userid = this.$tools.getUserId()
      const { signUserId } = task.task
      const { task_type, draft, view, call, share } = task_config
      if (signUserId && Number(signUserId) !== Number(userid)) {
        return false
      }
      if (auditMode) {
        return false
      }
      if (view) {
        // if (name === 'fun_template_my' && params && params.type === 'sell' ) {
        if (name === 'fun_template_my') {
          return true
        }
        return false
      }
      if (draft) {
        return true
      }
      if (share) {
        return false
      }
      // if (call) {
      //   return false
      // }
      if (!modify_mark) {
        return false
      }

      // 自己是教学作者可以直接编辑
      // // console.log(task_type,signUserId , userid, draft )
      if (task_type == 2 ) {
        if (signUserId == userid) {
          return true
        }else{
          return false
        }
      }
      if (task_type == 3) {
        return false
      }
      return true
    },
    use(){
      const { task_config,task } = this.$store.state
      const userid = this.$tools.getUserId()
      const { signUserId, buy } = task.task
      const { task_type, teach, view, call } = task_config
      if (task_type == 2 ) {
        if (signUserId == userid) {
          return true
        } else {
          if (buy) {
            return true
          }
          return false
        }
      }
      // 有教学内容 并且购买可以使用
      if (teach && buy) {
        return true
      }
      // (实例 || 调用 || 预览) && 自己是作者
      if ((task_type == 3 || call || view) && signUserId == userid) {
        return true
      }
      return false
    },
    preview(){
      const {task_config,task} = this.$store.state;
      const userid = this.$tools.getUserId();
      const {signUserId, buy} = task.task;
      const {teach} = task_config;
      // 有教学内容 && 未购买 && 自己不是作者
      if(teach && !buy && signUserId != userid){
        return true;
      }
      return false;
    },
    // 维护
    maintain(){
      const {task_config,task} = this.$store.state;
      const {name, params} = this.$route
      // console.log(this.$route)
      const userid = this.$tools.getUserId();
      const {signUserId} = task.task;
      const {view, call, task_type, share} = task_config;
      if(signUserId == userid){
        if(share){
          return true;
        }
        if(call){
          return true
        }
        if(view){
          if(name === 'fun_template_my' && params && params.type === 'sell' ){
            return false
          }
          return true;
        }
        if(task_type == 3){
          return true;
        }
      }
      return false;
    },
    buy(){
      const {task_config,task} = this.$store.state;
      const userid = this.$tools.getUserId();
      const {signUserId, buy} = task.task;
      const {teach, price} = task_config;
      // 有教学内容 && 未购买 && 自己不是作者
      if(teach && !buy && signUserId != userid){
        if(Number(price) === 0){
          return true;
        }
      }
      return false;
    },
  },
  methods:{
    changePage(status){
      this.page_status = status;
      if(status == 'price'){
        this.$nextTick(function(){
          this.$refs.page_price.inputFocus();
        })
      }
    },
    async doSumbit () {
      const { task_type, draft, teachType } = this.$store.state.task_config
      const { task } = this.$store.state
      const { id, teachingType } = task.task
      // 检查是否满足条件
      const canAudit = this.chenkAudit()
      if (!canAudit) {
        return
      }
      store.state.task_config.task_type = 2

      if (store.state.task_config.task_type === 2) {
        // console.log(task_type)
        if (this.task.task.checkstatus === 2) {
          this.$notice ({
            desc: '正在审核, 无法再次提交',
            type: 'error'
          })
          return
        }
        // 新建任务
        if (!id) {
          const res = await this.$api.moduleTask.createDraft({ config:{create_teach: true}})
          if (res.message === 'success') {
            this.task.task.id = res.contents.task.id
            this.task.task.draft = res.contents.task.draft
            this.$store.state.task_config.draft = true
            this.task.task.taskType = res.contents.task.taskType
          }
          this.task.task = Object.assign(
            {},
            res.contents.task,
            this.task.task
          )
          // this.task.task = res.contents.task
          this.task.taskDeatails = JSON.parse(res.contents.task.controls)
          store.commit('set_task_original', Object.assign(
            {},
            this.task,
            res.contents
          ))
        }
        const submitData = {
          checkstatus: 2
        }
        if (task.task.checkType) {
          submitData.checkType = task.task.checkType
        }
        if (task.task.checkUserId) {
          submitData.checkUserId = task.task.checkUserId
        }
        // 提交审核
        const res = await this.$api.moduleTask.editTeach(submitData)
        if (res.message === 'success') {
          // checkType == 1 时 不需要审核
          if (task.task.checkType !== 1) {
            this.task.task.checkstatus = 2
          }
          // 如果teachingType === 3 则不追加在草稿中
          if (teachingType !== 3) {
            const { draft_list } = store.state
            if (draft_list.length) {
              const index = draft_list.findIndex((unit) => {
                return Number(unit.id) === Number(this.task.task.id)
              })
              if (index !== -1) {
                // const bak = draft_list[index]
                draft_list.splice(index, 1)
                draft_list.unshift(this.task.task)
              }
            }
          }
          if (this.$route.name === 'fun_template_my' && this.$route.params.type === 'created') {
            if (Number(this.teachingType) === Number(teachingType)) {
              // 我创建的如果存在 则删除
              if (this.template_my_list && this.template_my_list.length) {
                const index = this.template_my_list.findIndex((unit) => {
                  return Number(unit.id) === Number(this.task.task.id)
                })
                if (index !== -1) {
                  // const bak = draft_list[index]
                  this.template_my_list.splice(index, 1)
                }
              }
            }

          } else {
            // 跳转到我创建的, 并设置对应的teachingType
            this.$router.push({
              name: 'fun_template_my',
              params: {
                type: 'created'
              }
            })
            store.commit(
              'setMyResourcePageStatus',
              {
                pageTag: 'teach_task',
                teachingType,
              }
            )
          }


          this.$notice({
            desc: '提交成功',
            type: 'success'
          })
        }
        return

      }
      const saveRes = await this.saveDraft()
      if (saveRes.message === 'success') {
        this.$api.moduleTask.closeTask()
        // 跳转到草稿箱
        this.$router.push({
          name: 'fun_template_draft',
          params: {
            type: task
          }
        })
      }
    },

    /**
     * 检查是否可以审核
     */
    chenkAudit () {
      const { state } = store
      const { task_config, task, teach_files } = state
      const { teachType } = task_config
      const {
        fileList,
        linkList,
        videoList
      } = teach_files
      console.log({
        teachType,
        task_config,
        task,
        teach_files
      })
      if (!teachType) {
        this.$notice({
          desc: '请选择教学类型',
          type: 'error'
        })
        return false
      }
      if (0 && (!fileList || fileList.length ===0) && (!linkList || linkList.length ===0) && (!videoList || videoList.length ===0)) {
        this.$notice({
          desc: '请上传教学内容',
          type: 'error'
        })
        return false
      }

      return true
    },
    async saveDraft () {

      const { draft } = this.$store.state.task_config
      const id = this.$store.state.task.task.id
      const { task } = store.state
      const { taskType } = task.task
      // const
      // console.log(task)
      if (id && taskType === 2) {
        // this.$api.moduleTask.editDraft()
        // console.log(this.$store.state.task_config)
        const res = await this.$api.moduleTask.editTeach()
        this.$notice({
          desc: '保存成功',
          type: 'success'
        })
        // 如果在草稿箱中 则更新
        if (this.$route.name === 'fun_template_draft') {
          const { draft_list } = store.state
          if (draft_list.length) {
            const index = draft_list.findIndex((unit) => {
              return Number(unit.id) === Number(this.task.task.id)
            })
            if (index !== -1) {
              draft_list.splice(index, 1)
              draft_list.unshift(this.task.task)
            }
          }
        }
        return res

      } else {
        const res = await this.$api.moduleTask.createDraft({ config:{create_teach: true}})
        if (res.message === 'success') {
          this.task.task = res.contents.task
          // this.task.task.id = res.contents.task.id
          // this.task.task.draft = res.contents.task.draft
          this.$store.state.task_config.draft = true
          this.$notice({
            desc: '保存成功',
            type: 'success'
          })
          return res
        }
      }

    },
    handleSavaTask(){
      this.$emit('handleSavaTask');
    },
    handleReEdit(val){
      this.$openConfirm({
        title:'提示',
        desc:'修改后需要重新提交审核,确定要修改教学内容?',
        sure:'确定',
        cancel:'取消',
        onSure: ()=> {
          this.modify_mark = val
          store.state.task_config.reEditTeach = true
        },
      })

    }

  },
  mounted(){
    const { task_config } = this.$store.state
    const { teach, task_type, share, call, draft } = task_config
    const { taskConfig } = this
    if (teach) {
      this.page_status = 'make'
    }
    if (task_type == 2 && !share && !call) {
      this.modify_mark = false
    }
    if (draft) {
      this.modify_mark = true
    }

    if (taskConfig.teachingPageStatue !== 'start') {
      this.page_status = taskConfig.teachingPageStatue
    }


  }
}
</script>
<style lang="scss" scoped>
.components_task_sidebar_teach_container_page{
  @include scrollbar;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  padding: 10px;

}
</style>
